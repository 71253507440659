import { NavLink } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { useTranslation } from "react-i18next";

import "./main.scss";

export const Footer = () => {
  const { t, i18n } = useTranslation();

  return (
    <footer id="footer" className="footer">
      <div className="footer__line-wrapper">
        <img
          src="./images/footer_page/line_before_footer.svg"
          alt="Horizontal Line"
          className="footer__line"
        ></img>
      </div>
      <div className="footer__content">
        <div className="footer__top">
          <div className="footer__block container">
            <NavLink to="/" className="footer__logo">
              <img
                src="./images/top_page/header/logo_text.svg"
                alt="USJOB Logo"
                className="header__logo-text"
              />
              <img
                src="./images/top_page/header/logo_icon.svg"
                alt=""
                className="header__logo-svg"
              />
            </NavLink>

            <ul className="footer__list footer__list--menu">
              <li className="footer__item">
                <HashLink smooth to="/#offer" className="footer__link">
                  {t("our_service")}
                </HashLink>
              </li>
              <li className="footer__item">
                <HashLink smooth to="/#how-it-works" className="footer__link">
                  {t("how_we_operate")}
                </HashLink>
              </li>
              <li className="footer__item">
                <HashLink smooth to="/#select-plan" className="footer__link">
                  {t("pricing")}
                </HashLink>
              </li>
              <li className="footer__item">
                <HashLink smooth to="/#home-clients" className="footer__link">
                  {t("reviews")}
                </HashLink>
              </li>
              <li className="footer__item">
                <HashLink smooth to="/#faq" className="footer__link">
                  FAQ
                </HashLink>
              </li>
            </ul>

            <ul className="footer__list footer__list--menu-2">
              <li className="footer__item">
                <a href="/" className="footer__link">
                  {t("terms_of_services")}
                </a>
              </li>
              <li className="footer__item">
                <a href="/" className="footer__link">
                  {t("privacy_policy")}
                </a>
              </li>
            </ul>

            <ul className="footer__list footer__list--contacts">
              <li className="footer__item footer__contacts-title">
                {t("contacts")}
              </li>
              <li className="footer__item footer__contact-info">
                <img
                  src="./images/top_page/header/phone.svg"
                  alt="Phone Icon"
                  className="footer__contact-icon"
                />
                <a
                  href="tel: +1 360 360 9713"
                  className="header-menu__contact-text"
                >
                  +1 360 360 9713
                </a>
              </li>
              <li className="footer__item footer__contact-info">
                <img
                  src="./images/top_page/header/email.svg"
                  alt="Phone Icon"
                  className="footer__contact-icon"
                />
                <a
                  href="mailto: support@usjobhero.com"
                  className="header-menu__contact-text"
                >
                  support@usjobhero.com
                </a>
              </li>
              <li className="footer__item footer__contact-info">
                <img
                  src="./images/top_page/header/telegram.svg"
                  alt="Phone Icon"
                  className="footer__contact-icon"
                />
                <a
                  href="https://t.me/cradlleson"
                  className="header-menu__contact-text"
                >
                  telegram
                </a>
              </li>
            </ul>
            <div className="footer__list footer__list--slogan">
              {t("footer_slogan")}
            </div>
          </div>
          <div className="footer__bottom">
            <h4 className="footer__bottom-text">
              All Rights Reserved{" "}
              <span class="break-on-phone">© 2024 usjobhero.com</span>
            </h4>
          </div>
        </div>
      </div>
    </footer>
  );
};

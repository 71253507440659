import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import Reviews from "./pages/reviews/Reviews";
import Home from "./pages/home/Home";
import PrePaymentDetails from "./pages/pre-payment-details/PrePaymentDetails";
import DemoRequest from "./pages/demo-request/DemoRequest";
import "./i18n";

import "./styles/main.scss";
import "./firebase";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/reviews",
    element: <Reviews />,
  },
  {
    path: "/pre-payment-details",
    element: <PrePaymentDetails />,
  },
  {
    path: "/demo-request",
    element: <DemoRequest />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
);

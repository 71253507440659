import { supabase } from "./supabaseClient";

export const saveUserDemoInfo = async (full_name, phone_number, email) => {
  try {
    const { data, error } = await supabase.from("user").upsert([
      {
        full_name,
        phone_number,
        email,
      },
    ]);

    if (error) throw error;

    return data;
  } catch (error) {
    console.error("Error saving user demo info:", error);
    return null;
  }
};

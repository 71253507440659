import React, { useRef, useEffect, useState } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { HashLink } from "react-router-hash-link";
import { NavLink, useNavigate } from "react-router-dom";

import { saveUserInfo } from "../../saveUserInfo";
import { Footer } from "../components/Footer";
import { Header } from "../components/Header";

import { SliderClients } from "./components/SliderClients";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../firebase";

import "./main.scss";

export default function Home() {
  const [openDropdown, setOpenDropdown] = useState(null);
  const fieldDropdownRef = useRef(null);
  const salaryDropdownRef = useRef(null);
  const navigate = useNavigate();
  const isMobile = window.innerWidth <= 768;
  const [startTime, setStartTime] = useState(null);

  const handleSelectClick = (dropdownName) => {
    setOpenDropdown((prev) => (prev === dropdownName ? null : dropdownName));
  };

  useEffect(() => {
    setStartTime(Date.now());

    return () => {
      if (startTime) {
        const pageViewDuration = Date.now() - startTime;
        logEvent(analytics, "page_view", {
          page_view_duration: pageViewDuration,
        });
      }
    };
  }, [startTime]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        fieldDropdownRef.current &&
        !fieldDropdownRef.current.contains(event.target) &&
        salaryDropdownRef.current &&
        !salaryDropdownRef.current.contains(event.target)
      ) {
        setOpenDropdown(null);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleUserFieldSelect = (option) => {
    handleInfoChange({ target: { name: "user_field", value: option } });
    setOpenDropdown(false);
  };

  const handleUserSalarySelect = (option) => {
    handleInfoChange({ target: { name: "user_salary", value: option } });
    setOpenDropdown(false);
  };

  const { t, i18n } = useTranslation();
  const sectionRef = useRef(null);
  const [selectedPlanColumn, setSelectedPlanColumn] = useState(1);

  const [activeRow, setActiveRow] = useState(null);

  const faqData = Array.from({ length: 8 }, (_, i) => ({
    question: t(`faq_${i + 1}_question`),
    answer: t(`faq_${i + 1}_answer`),
  }));

  const [quoteInfo, setQuoteInfo] = useState({
    user_email: "",
    user_field: "",
    user_salary: "",
  });

  const [errorMessage, setErrorMessage] = useState({
    user_email: false,
    user_field: false,
    user_salary: false,
  });

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, []);

  const resetError = () => {
    setErrorMessage({
      user_email: false,
      user_field: false,
      user_salary: false,
    });
  };

  const handleRowClick = (index) => {
    if (activeRow === index) {
      setActiveRow(null); // Hide the card if the same row is clicked again
    } else {
      setActiveRow(index); // Show the card for the clicked row
    }
  };

  const handleInfoChange = (event) => {
    resetError();

    const name = event.target.name;
    const oldObj = quoteInfo;
    oldObj[name] = event.target.value;

    setQuoteInfo({
      ...oldObj,
    });
  };

  function handleFocus(event) {
    event.target.placeholder = "";
  }

  function handleBlur(event) {
    if (!event.target.value) {
      event.target.placeholder = t("enter_email");
    }
  }

  const handleSubmitUserInfo = async (e) => {
    if (
      !quoteInfo.user_email ||
      !quoteInfo.user_field ||
      !quoteInfo.user_salary
    ) {
      return;
    }

    await saveUserInfo(
      quoteInfo.user_email,
      quoteInfo.user_field,
      quoteInfo.user_salary,
    );
  };

  const handleRedirectToStripe = async () => {
    const { user_email, user_field, user_salary } = quoteInfo;
    if (user_email && user_field && user_salary) {
      let stripeUrl;

      switch (selectedPlanColumn) {
        case 0:
          stripeUrl = `https://buy.stripe.com/5kAaI8aPncnWadyaEE?prefilled_email=${encodeURIComponent(user_email)}`;
          break;
        case 1:
          stripeUrl = `https://buy.stripe.com/14kdUk9LjgEc0CYfYZ?prefilled_email=${encodeURIComponent(user_email)}&prefilled_promo_code=DREAMJOB`;
          break;
        case 2:
          stripeUrl = `https://buy.stripe.com/aEUdUk3mVbjS85q003?prefilled_email=${encodeURIComponent(user_email)}`;
          break;
        default:
          console.error("Invalid plan selected");
          return;
      }

      window.location.href = stripeUrl;
    } else {
      setErrorMessage({
        user_email: !user_email,
        user_field: !user_field,
        user_salary: !user_salary,
      });

      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  const handleColumnClick = (columnIndex) => {
    setSelectedPlanColumn(
      columnIndex === selectedPlanColumn ? columnIndex : columnIndex,
    );
  };

  const handlePlanSelection = (planColumn, plan, price, discount, duration) => {
    if (quoteInfo.user_email && quoteInfo.user_field && quoteInfo.user_salary) {
      handleRedirectToStripe();
    } else {
      setTimeout(() => {
        navigate("/pre-payment-details", {
          state: {
            selectedPlanColumn: planColumn,
            selectedPlan: plan,
            planPrice: price,
            planDiscount: discount,
            planDuration: duration,
          },
          replace: true,
        });
      }, 0);
    }
  };

  return (
    <div className="page">
      <Header />

      <main className="main">
        <div className="main__content">
          <section className="home-top">
            <div className="home-top__block container">
              <div className="home-top__text-block">
                <h2 className="page-title home-top__page-title">
                  <span className="page-title--yellow">{t("best_way")}</span>{" "}
                  <span>{t("to_get")}</span>
                </h2>
                <h2 className="page-title home-top__page-title">
                  <span>{t("to_get_a_dream_job")}</span>
                </h2>
                <h2 className="page-title home-top__page-title">
                  <span>{t("in_the_us_and_europe")}</span>
                </h2>

                <div className="home-top__illustration home-top__illustration--phone">
                  <img src="./images/top_page/illustration.svg" alt="" />
                </div>

                <div className="home-top__block-under-title">
                  <p className="home-top__sub-text">
                    {t("tell_us_what_are_you_looking_for")}
                  </p>
                  <div className="home-top__field">
                    <input
                      type="text"
                      name="user_email"
                      placeholder={t("enter_email")}
                      value={quoteInfo["user_email"]}
                      className={classNames("home-top__input", {
                        "home-top__input--danger": errorMessage["user_email"],
                      })}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                      onChange={handleInfoChange}
                    />
                  </div>
                  <div
                    ref={fieldDropdownRef}
                    className="home-top__field home-top__field--with-arrow"
                  >
                    <div
                      className={classNames("home-top__select", {
                        "home-top__select--danger": errorMessage["user_field"],
                      })}
                      tabIndex="0"
                      onClick={() => handleSelectClick("user_field")}
                    >
                      <span
                        className={`home-top__select-value ${quoteInfo["user_field"] ? "home-top__select-value--selected" : ""}`}
                      >
                        {quoteInfo["user_field"] || t("whats_your_field")}
                      </span>
                      <img
                        src="./images/top_page/header/dropdown_arrow.svg"
                        alt="Dropdown Arrow"
                        className="home-top__arrow"
                      />
                    </div>
                    {openDropdown === "user_field" && (
                      <ul className="home-top__options">
                        <li onClick={() => handleUserFieldSelect("Technology")}>
                          Technology
                        </li>
                        <li onClick={() => handleUserFieldSelect("Design")}>
                          Design
                        </li>
                        <li onClick={() => handleUserFieldSelect("Analytics")}>
                          Analytics
                        </li>
                        <li onClick={() => handleUserFieldSelect("Marketing")}>
                          Marketing
                        </li>
                        <li onClick={() => handleUserFieldSelect("Finance")}>
                          Finance
                        </li>
                        <li onClick={() => handleUserFieldSelect("Sales")}>
                          Sales
                        </li>
                        <li
                          onClick={() =>
                            handleUserFieldSelect("Customer Service")
                          }
                        >
                          Customer Service
                        </li>
                        <li onClick={() => handleUserFieldSelect("Creative")}>
                          Creative
                        </li>
                      </ul>
                    )}
                  </div>
                  <div
                    ref={salaryDropdownRef}
                    className="home-top__field home-top__field--with-arrow"
                  >
                    <div
                      className={classNames("home-top__select", {
                        "home-top__select--danger": errorMessage["user_salary"],
                      })}
                      tabIndex="0"
                      onClick={() => handleSelectClick("user_salary")}
                    >
                      <span
                        className={`home-top__select-value ${quoteInfo["user_salary"] ? "home-top__select-value--selected" : ""}`}
                      >
                        {quoteInfo["user_salary"] ||
                          t("what_salary_do_you_want_to_earn")}
                      </span>
                      <img
                        src="./images/top_page/header/dropdown_arrow.svg"
                        alt="Dropdown Arrow"
                        className="home-top__arrow"
                      />
                    </div>
                    {openDropdown === "user_salary" && (
                      <ul className="home-top__options">
                        <li
                          onClick={() =>
                            handleUserSalarySelect("Less than $50,000")
                          }
                        >
                          Less than $50,000
                        </li>
                        <li
                          onClick={() =>
                            handleUserSalarySelect("$50,000 - $90,000")
                          }
                        >
                          $50,000 - $90,000
                        </li>
                        <li
                          onClick={() =>
                            handleUserSalarySelect("$90,000 - $130,000")
                          }
                        >
                          $90,000 - $130,000
                        </li>
                        <li
                          onClick={() =>
                            handleUserSalarySelect("$130,000 - $170,000")
                          }
                        >
                          $130,000 - $170,000
                        </li>
                        <li
                          onClick={() =>
                            handleUserSalarySelect("$170,000 - $200,000")
                          }
                        >
                          $170,000 - $200,000
                        </li>
                        <li
                          onClick={() =>
                            handleUserSalarySelect("More than $200,000")
                          }
                        >
                          More than $200,000
                        </li>
                      </ul>
                    )}
                  </div>
                  <HashLink
                    smooth
                    to="/#select-plan"
                    className="header-menu__link"
                  >
                    <button
                      onClick={handleSubmitUserInfo}
                      className="home-top__button"
                    >
                      {t("start_job_search")}
                    </button>
                  </HashLink>
                  <NavLink to="/demo-request" className="header-menu__link">
                    <button
                      onClick={handleSubmitUserInfo}
                      className="home-top__button-2"
                    >
                      {t("request_a_free_demo")}
                    </button>
                  </NavLink>
                </div>
              </div>

              <div className="home-top__illustration home-top__illustration--desktop">
                <img
                  src="./images/top_page/illustration.svg"
                  alt=""
                  className="home-top__illustration-img"
                />
              </div>
            </div>
            {/* <div class="gradient"></div> */}
          </section>

          <section id="offer" className="home-offer">
            <div className="home-offer__content container">
              <h2 className="page-title home-offer__title">
                {t("what_we_offer")}
              </h2>

              <div className="home-offer__block">
                <div className="home-offer__card home-offer__card--1">
                  <img
                    src="./images/what_offer_page/offer_1.svg"
                    alt=""
                    className="home-offer__img"
                  />

                  <h4 className="home-offer__card-title">
                    {t("help_with_resume")}
                  </h4>

                  <p className="home-offer__card-info">
                    {t("help_with_resume_desc")}
                  </p>
                </div>

                <img
                  src="./images/numbers/horizontal_curve_1.svg"
                  alt="Horizontal Line"
                  class="home-offer__horizontal-line"
                ></img>

                <div className="home-offer__card home-offer__card--2">
                  <img
                    src="./images/what_offer_page/offer_2.svg"
                    alt=""
                    className="home-offer__img"
                  />

                  <h4 className="home-offer__card-title">
                    {t("applying_to_jobs")}
                  </h4>

                  <p className="home-offer__card-info">
                    {t("applying_to_jobs_desc")}
                  </p>
                </div>

                <img
                  src="./images/numbers/horizontal_curve_2.svg"
                  alt="Horizontal Line"
                  class="home-offer__horizontal-line"
                ></img>

                <div className="home-offer__card home-offer__card--3">
                  <img
                    src="./images/what_offer_page/offer_3.svg"
                    alt=""
                    className="home-offer__img"
                  />

                  <h4 className="home-offer__card-title">
                    {t("linkedin_management")}
                  </h4>

                  <p className="home-offer__card-info">
                    {t("linkedin_management_desc")}
                  </p>
                </div>

                <img
                  src="./images/numbers/horizontal_curve_1.svg"
                  alt="Horizontal Line"
                  class="home-offer__horizontal-line"
                ></img>

                <div className="home-offer__card home-offer__card--4">
                  <img
                    src="./images/what_offer_page/offer_4.svg"
                    alt=""
                    className="home-offer__img"
                  />

                  <h4 className="home-offer__card-title">
                    {t("professional_connections")}
                  </h4>

                  <p className="home-offer__card-info">
                    {t("professional_connections_desc")}
                  </p>
                </div>

                <img
                  src="./images/numbers/horizontal_curve_2.svg"
                  alt="Horizontal Line"
                  class="home-offer__horizontal-line"
                ></img>
              </div>
            </div>
          </section>

          <section id="how-it-works" className="how-it-works">
            <div class="how-it-works__content container">
              <h2 class="page-title how-it-works__title">
                {t("how_it_works")}
              </h2>
              <div class="how-it-works__block">
                <img
                  src="./images/how_it_works/how_it_works.svg"
                  alt=""
                  class="how-it-works__img"
                />
                <div class="how-it-works__steps">
                  <div class="how-it-works__step">
                    <div class="how-it-works__number">01</div>
                    <div class="how-it-works__info">
                      <h3 class="how-it-works__info_title">
                        {t("how_it_works_step_1_title")}
                      </h3>
                      <p class="how-it-works__info_desc">
                        {t("how_it_works_step_1_desc")}
                      </p>
                    </div>
                  </div>
                  <div class="how-it-works__step">
                    <div class="how-it-works__number">02</div>
                    <div class="how-it-works__info">
                      <h3 class="how-it-works__info_title">
                        {t("how_it_works_step_2_title")}
                      </h3>
                      <p class="how-it-works__info_desc">
                        {t("how_it_works_step_2_desc")}
                      </p>
                    </div>
                  </div>
                  <div class="how-it-works__step">
                    <div class="how-it-works__number">03</div>
                    <div class="how-it-works__info">
                      <h3 class="how-it-works__info_title">
                        {t("how_it_works_step_3_title")}
                      </h3>
                      <p class="how-it-works__info_desc">
                        {t("how_it_works_step_3_desc")}
                      </p>
                    </div>
                  </div>
                  <HashLink
                    smooth
                    to="/#select-plan"
                    ref={sectionRef}
                    className="header-menu__link"
                  >
                    <button className="how-it-works__button">
                      {t("start")}
                    </button>
                  </HashLink>
                </div>
              </div>
            </div>
          </section>

          <div class="separator separator__first"></div>

          <section class="numbers">
            <div class="numbers__content container">
              <h2 class="page-title numbers__title">{t("some_numbers")}</h2>
              <div class="numbers__block">
                <div class="column">
                  <img
                    src="./images/numbers/star.svg"
                    alt="Star"
                    class="icon"
                  ></img>
                  <div class="title">{t("92_percent_success")}</div>
                  <div class="description">{t("92_percent_success_desc")}</div>
                </div>
                <img
                  src="./images/numbers/vertical_curve_1.svg"
                  alt="Vertical Line"
                  class="vertical-line"
                ></img>
                <img
                  src="./images/numbers/horizontal_curve.svg"
                  alt="Horizontal Line"
                  class="horizontal-line"
                ></img>
                <div class="column">
                  <div class="column-content">
                    <img
                      src="./images/numbers/star.svg"
                      alt="Star"
                      class="icon"
                    ></img>
                    <div class="title">{t("220_hours")}</div>
                    <div class="description">{t("220_hours_desc")}</div>
                  </div>
                </div>
                <img
                  src="./images/numbers/vertical_curve_2.svg"
                  alt="Vertical Line"
                  class="vertical-line"
                ></img>
                <img
                  src="./images/numbers/horizontal_curve.svg"
                  alt="Horizontal Line"
                  class="horizontal-line"
                ></img>
                <div class="column">
                  <div class="column-content">
                    <img
                      src="./images/numbers/star.svg"
                      alt="Star"
                      class="icon"
                    ></img>
                    <div class="title">{t("3k_plus_clients")}</div>
                    <div class="description">{t("3k_plus_clients_desc")}</div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section id="select-plan">
            <div className="home-clients__content container">
              <div className="container">
                <h2 className="page-title home-clients__title">
                  {t("select_plan")}
                </h2>
              </div>

              <div class="pricing">
                <div
                  className={`column_plan ${selectedPlanColumn === 0 ? "selected" : ""}`}
                  onMouseEnter={() => handleColumnClick(0)}
                >
                  <div class="section-1">{t("plan_1_name")}</div>
                  <div class="section-2">
                    <div class="row-2">$199</div>
                    <div class="row-3">({t("plan_1_duration")})</div>
                  </div>
                  <div class="section-3">{t("plan_1_desc")}</div>
                  <div class="section-4">
                    <ul>
                      <li>{t("plan_1_item_1")}</li>
                      <li>{t("plan_1_item_2")}</li>
                      <li>{t("plan_1_item_3")}</li>
                      <li>{t("plan_1_item_4")}</li>
                    </ul>
                  </div>
                  {(selectedPlanColumn === 0 || isMobile) && (
                    <NavLink
                      smooth
                      to="/"
                      ref={sectionRef}
                      className="header-menu__link"
                    >
                      <button
                        onClick={() =>
                          handlePlanSelection(
                            0,
                            t("plan_1_name"),
                            "$199",
                            null,
                            t("plan_1_duration"),
                          )
                        }
                        className="pricing__button"
                      >
                        {t("search")}
                      </button>
                    </NavLink>
                  )}
                </div>
                <div
                  className={`column_plan ${selectedPlanColumn === 1 ? "selected" : ""}`}
                  onMouseEnter={() => handleColumnClick(1)}
                >
                  <div class="section-1">{t("plan_2_name")}</div>
                  <div class="section-2 section-2__column-2">
                    <div class="row-1">$399</div>
                    <div class="row-2">$349</div>
                    <div class="row-3">({t("plan_2_duration")})</div>
                  </div>
                  <div class="section-3">{t("plan_2_desc")}</div>
                  <div class="section-4">
                    <ul>
                      <li>{t("plan_2_item_1")}</li>
                      <li>{t("plan_2_item_2")}</li>
                      <li>{t("plan_2_item_3")}</li>
                      <li>{t("plan_2_item_4")}</li>
                    </ul>
                  </div>
                  {(selectedPlanColumn === 1 || isMobile) && (
                    <NavLink
                      smooth
                      to="/"
                      ref={sectionRef}
                      className="header-menu__link"
                    >
                      <button
                        onClick={() =>
                          handlePlanSelection(
                            1,
                            t("plan_2_name"),
                            "$349",
                            "$399",
                            t("plan_2_duration"),
                          )
                        }
                        className="pricing__button"
                      >
                        {t("search")}
                      </button>
                    </NavLink>
                  )}
                </div>
                <div
                  className={`column_plan ${selectedPlanColumn === 2 ? "selected" : ""}`}
                  onMouseEnter={() => handleColumnClick(2)}
                >
                  <div class="section-1">{t("plan_3_name")}</div>
                  <div class="section-2">
                    <div class="row-2">$449</div>
                    <div class="row-3">({t("plan_3_duration")})</div>
                  </div>
                  <div class="section-3">{t("plan_3_desc")}</div>
                  <div class="section-4">
                    <ul>
                      <li>{t("plan_3_item_1")}</li>
                      <li>{t("plan_3_item_2")}</li>
                      <li>{t("plan_3_item_3")}</li>
                      <li>{t("plan_3_item_4")}</li>
                    </ul>
                  </div>
                  {(selectedPlanColumn === 2 || isMobile) && (
                    <NavLink
                      smooth
                      to="/"
                      ref={sectionRef}
                      className="header-menu__link"
                    >
                      <button
                        onClick={() =>
                          handlePlanSelection(
                            2,
                            t("plan_3_name"),
                            "$449",
                            null,
                            t("plan_3_duration"),
                          )
                        }
                        className="pricing__button"
                      >
                        {t("search")}
                      </button>
                    </NavLink>
                  )}
                </div>
              </div>
            </div>
          </section>

          <section id="home-clients" className="home-clients">
            <div className="home-clients__content container__reviews">
              <div className="container">
                <h2 className="page-title home-clients__title">
                  {t("clients_reviews")}
                </h2>
              </div>

              <SliderClients />
            </div>
          </section>

          <div class="separator separator__second"></div>

          <section id="faq" className="home-clients">
            <div className="home-clients__content container">
              <div className="container">
                <h2 className="page-title home-clients__title">FAQ</h2>
              </div>

              <div className="faq-cards">
                {faqData.map((item, index) => (
                  <div key={index} className="faq-row-container">
                    <div
                      className="faq-row"
                      onClick={() => handleRowClick(index)}
                    >
                      <p
                        className={`faq-row-text ${activeRow === index ? "faq-row-text--yellow" : ""}`}
                      >
                        {item.question}
                      </p>
                      <img
                        src={
                          activeRow === index
                            ? "./images/top_page/faq/gold_arrow_towards_top.svg"
                            : "./images/top_page/faq/grey_arrow_towards_bottom.svg"
                        }
                        alt=""
                        className="faq-arrow"
                      />
                    </div>
                    {activeRow === index && (
                      <div className="faq-card">
                        <p className="faq-card-text">{item.answer}</p>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </section>
        </div>
      </main>

      <Footer />
    </div>
  );
}

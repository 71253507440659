import React, { useRef, useEffect, useState } from "react";
import classNames from "classnames";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { HashLink } from "react-router-hash-link";

import { saveUserInfo } from "../../saveUserInfo";

import "./main.scss";

export default function PrePaymentDetails() {
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedPlanColumn, setSelectedPlanColumn] = useState(
    location.state?.selectedPlanColumn || "",
  );
  const [selectedPlan, setSelectedPlan] = useState(
    location.state?.selectedPlan || "",
  );
  const [planPrice, setPlanPrice] = useState(location.state?.planPrice || "");
  const [planDiscount, setPlanDiscount] = useState(
    location.state?.planDiscount || "",
  );
  const [planDuration, setPlanDuration] = useState(
    location.state?.planDuration || "",
  );

  const [openDropdown, setOpenDropdown] = useState(null);
  const fieldDropdownRef = useRef(null);
  const salaryDropdownRef = useRef(null);

  const handleSelectClick = (dropdownName) => {
    setOpenDropdown((prev) => (prev === dropdownName ? null : dropdownName));
  };

  const returnBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        fieldDropdownRef.current &&
        !fieldDropdownRef.current.contains(event.target) &&
        salaryDropdownRef.current &&
        !salaryDropdownRef.current.contains(event.target)
      ) {
        setOpenDropdown(null);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleUserFieldSelect = (option) => {
    handleInfoChange({ target: { name: "user_field", value: option } });
    setOpenDropdown(false);
  };

  const handleUserSalarySelect = (option) => {
    handleInfoChange({ target: { name: "user_salary", value: option } });
    setOpenDropdown(false);
  };

  const { t, i18n } = useTranslation();
  const sectionRef = useRef(null);

  const [activeRow, setActiveRow] = useState(null);

  const [quoteInfo, setQuoteInfo] = useState({
    user_email: "",
    user_field: "",
    user_salary: "",
  });

  const [errorMessage, setErrorMessage] = useState({
    user_email: false,
    user_field: false,
    user_salary: false,
  });

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, []);

  const resetError = () => {
    setErrorMessage({
      user_email: false,
      user_field: false,
      user_salary: false,
    });
  };

  const handleInfoChange = (event) => {
    resetError();

    const name = event.target.name;
    const oldObj = quoteInfo;
    oldObj[name] = event.target.value;

    setQuoteInfo({
      ...oldObj,
    });
  };

  function handleFocus(event) {
    event.target.placeholder = "";
  }

  function handleBlur(event) {
    if (!event.target.value) {
      event.target.placeholder = t("enter_email");
    }
  }

  const handleSubmitUserInfo = async (e) => {
    if (
      !quoteInfo.user_email ||
      !quoteInfo.user_field ||
      !quoteInfo.user_salary
    ) {
      return;
    }

    await saveUserInfo(
      quoteInfo.user_email,
      quoteInfo.user_field,
      quoteInfo.user_salary,
    );
  };

  const handleRedirectToStripe = async () => {
    const { user_email, user_field, user_salary } = quoteInfo;
    if (user_email && user_field && user_salary) {
      let stripeUrl;
      switch (selectedPlanColumn) {
        case 0:
          stripeUrl = `https://buy.stripe.com/5kAaI8aPncnWadyaEE?prefilled_email=${encodeURIComponent(user_email)}`;
          break;
        case 1:
          stripeUrl = `https://buy.stripe.com/14kdUk9LjgEc0CYfYZ?prefilled_email=${encodeURIComponent(user_email)}&prefilled_promo_code=DREAMJOB`;
          break;
        case 2:
          stripeUrl = `https://buy.stripe.com/aEUdUk3mVbjS85q003?prefilled_email=${encodeURIComponent(user_email)}`;
          break;
        default:
          console.error("Invalid plan selected");
          return;
      }

      window.location.href = stripeUrl;
    } else {
      setErrorMessage({
        user_email: !user_email,
        user_field: !user_field,
        user_salary: !user_salary,
      });

      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  const handleButtonClick = () => {
    handleSubmitUserInfo();
    handleRedirectToStripe();
  };

  return (
    <div className="page">
      <div className="header__content container">
        <div className="header__left-block">
          <NavLink to="/" className="header__logo">
            <img
              src="./images/top_page/header/logo_text.svg"
              alt="USJOB Logo"
              className="header__logo-text"
            />
            <img
              src="./images/top_page/header/logo_icon.svg"
              alt=""
              className="header__logo-svg"
            />
          </NavLink>
        </div>
      </div>
      <main className="main">
        <div className="main__content2">
          <section className="home-top">
            <div className="pre-payment__block container">
              <div className="pre-payment__text-block">
                <div className="pre-payment__title-container">
                  <h2 className="page-title home-top__page-title">
                    <span className="page-title--yellow">{selectedPlan}</span>{" "}
                  </h2>
                  <span className="pre-payment__price">{planPrice}</span>
                  <div className="pre-payment__price-column">
                    <div className="pre-payment__price-row pre-payment__price-row--strike">
                      {planDiscount}
                    </div>
                    <div className="pre-payment__price-row">
                      ({planDuration})
                    </div>
                  </div>
                </div>
                <div className="home-top__block-under-title">
                  <p className="home-top__sub-text">
                    {t("tell_us_what_are_you_looking_for")}
                  </p>
                  <div className="home-top__field">
                    <input
                      type="text"
                      name="user_email"
                      placeholder={t("enter_email")}
                      value={quoteInfo["user_email"]}
                      className={classNames("home-top__input", {
                        "home-top__input--danger": errorMessage["user_email"],
                      })}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                      onChange={handleInfoChange}
                    />
                  </div>
                  <div
                    ref={fieldDropdownRef}
                    className="home-top__field home-top__field--with-arrow"
                  >
                    <div
                      className={classNames("home-top__select", {
                        "home-top__select--danger": errorMessage["user_field"],
                      })}
                      tabIndex="0"
                      onClick={() => handleSelectClick("user_field")}
                    >
                      <span
                        className={`home-top__select-value ${quoteInfo["user_field"] ? "home-top__select-value--selected" : ""}`}
                      >
                        {quoteInfo["user_field"] || t("whats_your_field")}
                      </span>
                      <img
                        src="./images/top_page/header/dropdown_arrow.svg"
                        alt="Dropdown Arrow"
                        className="home-top__arrow"
                      />
                    </div>
                    {openDropdown === "user_field" && (
                      <ul className="home-top__options">
                        <li onClick={() => handleUserFieldSelect("Technology")}>
                          Technology
                        </li>
                        <li onClick={() => handleUserFieldSelect("Design")}>
                          Design
                        </li>
                        <li onClick={() => handleUserFieldSelect("Analytics")}>
                          Analytics
                        </li>
                        <li onClick={() => handleUserFieldSelect("Marketing")}>
                          Marketing
                        </li>
                        <li onClick={() => handleUserFieldSelect("Finance")}>
                          Finance
                        </li>
                        <li onClick={() => handleUserFieldSelect("Sales")}>
                          Sales
                        </li>
                        <li
                          onClick={() =>
                            handleUserFieldSelect("Customer Service")
                          }
                        >
                          Customer Service
                        </li>
                        <li onClick={() => handleUserFieldSelect("Creative")}>
                          Creative
                        </li>
                      </ul>
                    )}
                  </div>
                  <div
                    ref={salaryDropdownRef}
                    className="home-top__field home-top__field--with-arrow"
                  >
                    <div
                      className={classNames("home-top__select", {
                        "home-top__select--danger": errorMessage["user_salary"],
                      })}
                      tabIndex="0"
                      onClick={() => handleSelectClick("user_salary")}
                    >
                      <span
                        className={`home-top__select-value ${quoteInfo["user_salary"] ? "home-top__select-value--selected" : ""}`}
                      >
                        {quoteInfo["user_salary"] ||
                          t("what_salary_do_you_want_to_earn")}
                      </span>
                      <img
                        src="./images/top_page/header/dropdown_arrow.svg"
                        alt="Dropdown Arrow"
                        className="home-top__arrow"
                      />
                    </div>
                    {openDropdown === "user_salary" && (
                      <ul className="home-top__options">
                        <li
                          onClick={() =>
                            handleUserSalarySelect("Less than $50,000")
                          }
                        >
                          Less than $50,000
                        </li>
                        <li
                          onClick={() =>
                            handleUserSalarySelect("$50,000 - $90,000")
                          }
                        >
                          $50,000 - $90,000
                        </li>
                        <li
                          onClick={() =>
                            handleUserSalarySelect("$90,000 - $130,000")
                          }
                        >
                          $90,000 - $130,000
                        </li>
                        <li
                          onClick={() =>
                            handleUserSalarySelect("$130,000 - $170,000")
                          }
                        >
                          $130,000 - $170,000
                        </li>
                        <li
                          onClick={() =>
                            handleUserSalarySelect("$170,000 - $200,000")
                          }
                        >
                          $170,000 - $200,000
                        </li>
                        <li
                          onClick={() =>
                            handleUserSalarySelect("More than $200,000")
                          }
                        >
                          More than $200,000
                        </li>
                      </ul>
                    )}
                  </div>
                  <button
                    onClick={handleButtonClick}
                    className="home-top__button"
                  >
                    {t("start_job_search")}
                  </button>
                  <button
                    className="pre-payment__back-button"
                    onClick={returnBack}
                  >
                    {t("back")}
                  </button>
                </div>
              </div>

              <div className="pre-payment__illustration">
                <img
                  src="./images/top_page/illustration.svg"
                  alt=""
                  className="pre-payment__illustration"
                />
              </div>
            </div>
          </section>
        </div>
      </main>
    </div>
  );
}

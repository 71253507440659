import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyAqG-f3UhVgNEVFeKmBNhCPtjxnAntFV5c",
  authDomain: "us-job-hero.firebaseapp.com",
  projectId: "us-job-hero",
  storageBucket: "us-job-hero.appspot.com",
  messagingSenderId: "206065612722",
  appId: "1:206065612722:web:1969493c01301f2110d6ab",
  measurementId: "G-RK0H1DP37K",
};

const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);

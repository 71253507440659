import { NavLink } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { useRef, useState, useEffect } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import "./main.scss";

export function Header({ headerClass }) {
  const { t, i18n } = useTranslation();

  const [openMenu, setOpenMenu] = useState(false);

  const languageCodes = ["en", "ua", "ru"];
  const languageDropdownRef = useRef(null);
  const [selectedLanguageCode, setSelectedLanguageCode] = useState("en");
  const [selectedLanguage, setSelectedLanguage] = useState(t("en"));
  const [shouldOpenLanguageDropdown, setShouldOpenLanguageDropdown] =
    useState(false);

  const handleMenu = () => {
    setOpenMenu(!openMenu);
  };

  const handleLanguageSelection = (languageCode) => {
    i18n.changeLanguage(languageCode);
    setSelectedLanguageCode(languageCode);
    setSelectedLanguage(t(languageCode));
    setShouldOpenLanguageDropdown(false);
  };

  const handleSelectClick = () => {
    setShouldOpenLanguageDropdown((prev) => !prev);
  };

  useEffect(() => {
    i18n.changeLanguage(selectedLanguageCode);
  }, [i18n, selectedLanguage]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        languageDropdownRef.current &&
        !languageDropdownRef.current.contains(event.target)
      ) {
        setShouldOpenLanguageDropdown(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <header
      className={classNames("header", headerClass, {
        "header--close": openMenu,
      })}
    >
      <div className="header__content container">
        <div className="header__left-block">
          <NavLink to="/" className="header__logo">
            <img
              src="./images/top_page/header/logo.svg"
              alt="USJOB Logo"
              className="header__logo-image"
            />
          </NavLink>
        </div>

        <div className="header__central-block">
          <ul className="header__nav-list">
            <li className="header__nav-item">
              <HashLink
                smooth
                to="/#offer"
                className="header__nav-link header__nav-link--about-us"
              >
                {t("our_service")}
              </HashLink>
            </li>
            <li className="header__nav-item">
              <HashLink
                smooth
                to="/#how-it-works"
                className="header__nav-link header__nav-link--about-us"
              >
                {t("how_we_operate")}
              </HashLink>
            </li>
            <li className="header__nav-item">
              <HashLink
                smooth
                to="/#select-plan"
                className="header__nav-link header__nav-link--about-us"
              >
                {t("pricing")}
              </HashLink>
            </li>
            <li className="header__nav-item">
              <HashLink
                smooth
                to="/#home-clients"
                className="header__nav-link header__nav-link--portfolio"
              >
                {t("reviews")}
              </HashLink>
            </li>
            <li className="header__nav-item">
              <HashLink
                smooth
                to="/#faq"
                className="header__nav-link header__nav-link--reviews"
              >
                FAQ
              </HashLink>
            </li>
          </ul>
        </div>
        <div className="header__right-block">
          <div className="header__contact-info">
            <img
              src="./images/top_page/header/phone.svg"
              alt="Phone Icon"
              className="header__phone-icon"
            />
            <img
              src="./images/top_page/header/telegram.svg"
              alt="Telegram Icon"
              className="header__telegram-icon"
            />
            <span className="header__phone-text">+1 360 360 9713</span>
          </div>
          <div ref={languageDropdownRef} className="header__language-dropdown">
            <span className="header__language-text" onClick={handleSelectClick}>
              {selectedLanguage}
            </span>
            <img
              src="./images/top_page/header/dropdown_arrow.svg"
              alt="Dropdown Arrow"
              className="header__dropdown-arrow"
              onClick={handleSelectClick}
            />
            {shouldOpenLanguageDropdown && (
              <div className="header__dropdown-content" id="dropdownContent">
                {languageCodes.map((code) => (
                  <a
                    key={code}
                    href="#"
                    onClick={() => handleLanguageSelection(code)}
                  >
                    {t(code)}
                  </a>
                ))}
              </div>
            )}
          </div>
          <img
            src="./images/top_page/header/menu_img.svg"
            alt=""
            className="header__menu-img header__menu-right"
            onClick={handleMenu}
          />
        </div>
      </div>

      <div
        className={classNames("header-menu", { "header-menu--open": openMenu })}
      >
        <div className="header-menu__content container">
          <div className="header__content">
            <div className="header__left-block">
              <NavLink to="/">
                <img
                  src="./images/top_page/header/logo.svg"
                  alt=""
                  className="header__logo"
                />
              </NavLink>
            </div>

            <div className="header__right-block">
              <img
                src="./images/top_page/header/close_img.svg"
                alt=""
                className="header__menu-img header-menu__close-img"
                onClick={handleMenu}
              />
            </div>
          </div>

          <ul className="header-menu__list">
            <li className="header-menu__item">
              <HashLink
                smooth
                to="/#offer"
                className="header-menu__link"
                onClick={handleMenu}
              >
                {t("our_service")}
              </HashLink>
            </li>
            <li className="header-menu__item">
              <HashLink
                smooth
                to="/#how-it-works"
                className="header-menu__link"
                onClick={handleMenu}
              >
                {t("how_we_operate")}
              </HashLink>
            </li>
            <li className="header-menu__item">
              <HashLink
                smooth
                to="/#select-plan"
                className="header-menu__link"
                onClick={handleMenu}
              >
                {t("pricing")}
              </HashLink>
            </li>
            <li className="header-menu__item">
              <HashLink
                smooth
                to="/#home-clients"
                className="header-menu__link"
                onClick={handleMenu}
              >
                {t("reviews")}
              </HashLink>
            </li>
            <li className="header-menu__item">
              <HashLink
                smooth
                to="/#faq"
                className="header-menu__link"
                onClick={handleMenu}
              >
                FAQ
              </HashLink>
            </li>
          </ul>

          <div className="header-menu__contact-block">
            <div className="header-menu__language-options">
              {languageCodes.map((code) => (
                <span
                  key={code}
                  onClick={() => handleLanguageSelection(code)}
                  className={classNames("header-menu__language", {
                    "header-menu__language--selected":
                      selectedLanguageCode === code,
                  })}
                >
                  {t(code)}
                </span>
              ))}
            </div>
            <div className="header-menu__text">
              <div className="header-menu__text-block">
                <img
                  src="./images/top_page/header/phone.svg"
                  alt=""
                  srcset=""
                  className="header-menu__icon"
                />

                <a
                  href="tel: +1 360 360 9713"
                  className="header-menu__contact-text"
                >
                  +1 360 360 9713
                </a>
              </div>
              <div className="header-menu__text-block">
                <img
                  src="./images/top_page/header/email.svg"
                  alt=""
                  srcset=""
                  className="header-menu__icon"
                />
                <a
                  href="mailto: support@usjobhero.com"
                  className="header-menu__contact-text"
                >
                  support@usjobhero.com
                </a>
              </div>
              <div className="header-menu__text-block">
                <img
                  src="./images/top_page/header/telegram.svg"
                  alt=""
                  srcset=""
                  className="header-menu__icon"
                />

                <a
                  href="https://t.me/cradlleson"
                  className="header-menu__contact-text"
                >
                  telegram
                </a>
              </div>
            </div>

            {/* <div className="header-menu__button-block"> */}
            <HashLink
              smooth
              to="/#select-plan"
              className="header-menu__link"
              onClick={handleMenu}
            >
              <button className="header-menu__button">
                {t("start_job_search")}
              </button>
            </HashLink>

            {/* </div> */}
          </div>
        </div>
      </div>
      {/* )} */}
    </header>
  );
}

import { supabase } from "./supabaseClient";

export const saveUserInfo = async (email, field, salary) => {
  try {
    const { data, error } = await supabase.from("user").upsert([
      {
        email,
        field,
        salary,
      },
    ]);

    if (error) throw error;

    return data;
  } catch (error) {
    console.error("Error saving user info:", error);
    return null;
  }
};

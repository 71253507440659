import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { NavLink, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CheckCircle } from "lucide-react";

import { saveUserDemoInfo } from "../../saveUserDemoInfo";

import "./main.scss";

export default function DemoRequest() {
  const navigate = useNavigate();
  const [showCheckmark, setShowCheckmark] = useState(false);

  const { t } = useTranslation();

  const [quoteInfo, setQuoteInfo] = useState({
    full_name: "",
    phone_number: "",
    user_email: "",
  });

  const [errorMessage, setErrorMessage] = useState({
    full_name: false,
    phone_number: false,
    user_email: false,
  });

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, []);

  const resetError = () => {
    setErrorMessage({
      full_name: false,
      phone_number: false,
      user_email: false,
    });
  };

  const handleInfoChange = (event) => {
    resetError();

    const name = event.target.name;
    const oldObj = quoteInfo;
    oldObj[name] = event.target.value;

    setQuoteInfo({
      ...oldObj,
    });
  };

  function handleFocus(event) {
    event.target.placeholder = "";
  }

  function handleBlur(event) {
    if (!event.target.value) {
      event.target.placeholder = t("enter_your_name");
    }
  }

  function handleBlur2(event) {
    if (!event.target.value) {
      event.target.placeholder = t("enter_phone_number");
    }
  }

  function handleBlur3(event) {
    if (!event.target.value) {
      event.target.placeholder = t("enter_email");
    }
  }

  const handleSubmitUserInfo = async (e) => {
    if (
      !quoteInfo.full_name ||
      !quoteInfo.phone_number ||
      !quoteInfo.user_email
    ) {
      return;
    }

    await saveUserDemoInfo(
      quoteInfo.full_name,
      quoteInfo.phone_number,
      quoteInfo.user_email,
    );
  };

  const handleButtonClick = () => {
    handleSubmitUserInfo();
    setShowCheckmark(true);
    setTimeout(() => {
      setShowCheckmark(false);
    }, 3000); // Hide checkmark after 3 seconds
  };

  return (
    <div className="page">
      <div className="header__content container">
        <div className="header__left-block">
          <NavLink to="/" className="header__logo">
            <img
              src="./images/top_page/header/logo_text.svg"
              alt="USJOB Logo"
              className="header__logo-text"
            />
            <img
              src="./images/top_page/header/logo_icon.svg"
              alt=""
              className="header__logo-svg"
            />
          </NavLink>
        </div>
      </div>
      <main className="main">
        <div className="main__content2">
          <section className="home-top">
            <div className="demo-request__block container">
              <div className="demo-request__text-block">
                <h2 className="page-title home-top__page-title">
                  <span>{t("schedule")}</span>{" "}
                </h2>
                <h2 className="page-title home-top__page-title">
                  <span className="page-title--blue">{t("a_free_demo")}</span>{" "}
                </h2>
                <div className="home-top__block-under-title">
                  <p className="home-top__sub-text">{t("demo_subtitle")}</p>
                  <div className="home-top__field">
                    <input
                      type="text"
                      name="full_name"
                      placeholder={t("enter_your_name")}
                      value={quoteInfo["full_name"]}
                      className={classNames("home-top__input", {
                        "home-top__input--danger": errorMessage["full_name"],
                      })}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                      onChange={handleInfoChange}
                    />
                  </div>
                  <div className="home-top__field">
                    <input
                      type="text"
                      name="phone_number"
                      placeholder={t("enter_phone_number")}
                      value={quoteInfo["phone_number"]}
                      className={classNames("home-top__input", {
                        "home-top__input--danger": errorMessage["phone_number"],
                      })}
                      onFocus={handleFocus}
                      onBlur={handleBlur2}
                      onChange={handleInfoChange}
                    />
                  </div>
                  <div className="home-top__field">
                    <input
                      type="text"
                      name="user_email"
                      placeholder={t("enter_email")}
                      value={quoteInfo["user_email"]}
                      className={classNames("home-top__input", {
                        "home-top__input--danger": errorMessage["user_email"],
                      })}
                      onFocus={handleFocus}
                      onBlur={handleBlur3}
                      onChange={handleInfoChange}
                    />
                  </div>
                  <button
                    onClick={handleButtonClick}
                    className="home-top__button-2"
                  >
                    {t("request_a_free_demo")}
                  </button>
                  <NavLink to="/" className="header-menu__link">
                    <button className="demo-request__back-button">
                      {t("back")}
                    </button>
                  </NavLink>
                </div>
              </div>

              <div className="demo-request__illustration">
                <img
                  src="./images/top_page/illustration.svg"
                  alt=""
                  className="demo-request__illustration"
                />
              </div>
            </div>
          </section>
        </div>
      </main>
      {showCheckmark && (
        <div className="checkmark-overlay">
          <CheckCircle size={64} color="#4CAF50" />
          <p className="success-message">{t("demo_requested_successfully")}</p>
          <p className="contact-message">{t("we_will_contact_you_shortly")}</p>
        </div>
      )}
    </div>
  );
}

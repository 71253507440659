/* eslint-disable react/jsx-no-comment-textnodes */
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Header } from "../components/Header";

import "./main.scss";
import { useEffect } from "react";

import { Footer } from "../components/Footer";

export default function Reviews() {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, []);

  return (
    <div className="reviews">
      <Header headerClass="header--none-shadow" />

      <main className="main">
        <section className="portfilio-top">
          <div className="portfolio-top__content container">
            <div className="reviews-top__text-block">
              <h2 className="page-title-reviews page-title-reviews--yellow reviews-main__page-title--position">
                {t("reviews")}
              </h2>
            </div>
          </div>
        </section>

        <section className="reviews-main">
          <div className="container">
            <div className="reviews-main__content">
              <div className="reviews-main__card reviews-main__card--position-1">
                <div className="reviews-main__top">
                  <img
                    src="./images/our_clients_page/stars.svg"
                    alt=""
                    className="home-clients__star"
                  />
                  <div className="reviews-main__salary">$180k+</div>
                </div>

                <p className="reviews-main__review">{t("review_1")}</p>

                <div className="reviews-main__review-bottom">
                  <img
                    src="./images/our_clients_page/review_1_photo.jpeg"
                    alt=""
                    className="reviews-main__client-img"
                  />

                  <div className="reviews-main__review-bottom-info">
                    <div className="home-clients__name-wrapper">
                      <span className="home-clients__name">
                        {t("review_1_name")}
                      </span>
                      <a
                        href="https://www.linkedin.com/in/ivan-tszian-43a211190/"
                        target="_blank"
                        className="home-clients__linkedin-link"
                      >
                        <img
                          src="./images/our_clients_page/linkedin_icon.svg"
                          alt="LinkedIn"
                          className="home-clients__linkedin-icon"
                        />
                      </a>
                    </div>

                    <div className="home-clients__position">
                      // {t("review_1_position")}
                    </div>
                  </div>
                </div>
              </div>

              <div className="reviews-main__card reviews-main__card--doshed-bg reviews-main__card--position-1">
                <div className="reviews-main__top">
                  <img
                    src="./images/our_clients_page/stars.svg"
                    alt=""
                    className="home-clients__star"
                  />
                </div>

                <p className="reviews-main__review">{t("review_2")}</p>

                <div className="reviews-main__review-bottom">
                  <img
                    src="./images/our_clients_page/review_2_photo.jpeg"
                    alt=""
                    className="reviews-main__client-img"
                  />

                  <div className="reviews-main__review-bottom-info">
                    <div className="home-clients__name-wrapper">
                      <span className="home-clients__name">
                        {t("review_2_name")}
                      </span>
                      <a
                        href="https://www.linkedin.com/in/denyszhak/"
                        target="_blank"
                        className="home-clients__linkedin-link"
                      >
                        <img
                          src="./images/our_clients_page/linkedin_icon.svg"
                          alt="LinkedIn"
                          className="home-clients__linkedin-icon"
                        />
                      </a>
                    </div>

                    <div className="home-clients__position">
                      // {t("review_2_position")}
                    </div>
                  </div>
                </div>
              </div>

              <div className="reviews-main__card reviews-main__card--doshed-bg reviews-main__card--position-2 reviews-main__card--change-bg-1">
                <div className="reviews-main__top">
                  <img
                    src="./images/our_clients_page/stars.svg"
                    alt=""
                    className="home-clients__star"
                  />
                </div>

                <p className="reviews-main__review">{t("review_3")}</p>

                <div className="reviews-main__review-bottom">
                  <img
                    src="./images/our_clients_page/review_3_photo.png"
                    alt=""
                    className="reviews-main__client-img"
                  />

                  <div className="reviews-main__review-bottom-info-gap">
                    <span className="reviews-main__name">
                      {t("review_3_name")}
                    </span>

                    <div className="reviews-main__position">
                      // {t("review_3_position")}
                    </div>
                  </div>
                </div>
              </div>

              <div className="reviews-main__card reviews-main__card--position-2 reviews-main__card--change-bg-2">
                <div className="reviews-main__top">
                  <img
                    src="./images/our_clients_page/stars.svg"
                    alt=""
                    className="home-clients__star"
                  />
                </div>

                <p className="reviews-main__review">{t("review_4")}</p>

                <div className="reviews-main__review-bottom">
                  <img
                    src="./images/our_clients_page/review_4_photo.png"
                    alt=""
                    className="reviews-main__client-img"
                  />

                  <div className="reviews-main__review-bottom-info-gap">
                    <span className="reviews-main__name">
                      {t("review_4_name")}
                    </span>

                    <div className="reviews-main__position">
                      // {t("review_4_position")}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <Footer />
    </div>
  );
}
